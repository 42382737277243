<template>
  <form-panel ref="formPanel"
              v-bind="submitConfig"
              :form="form"
              :submitBefore="submitBefore"
              :submitSuccess="submitSuccess">
    <el-form-item label="调查类型"
                  :rules="[{ required: true, message: '请输入调查类型', trigger: 'change' }]"
                  prop="categoryId">
      <v-select v-model="form.categoryId"
                :options="categoryOps"
                @change="categoryChange"
                :width="width"></v-select>
    </el-form-item>
    <el-form-item label="活动组织类型"
                  v-show="isActivity"
                  :rules="[{ required: isActivity, message: '活动组织类型', trigger: 'change' }]"
                  prop="activityPublisherType">
      <v-select v-model="form.activityPublisherType"
                :options="activityPublisherTypeOps"
                :width="width"></v-select>
    </el-form-item>
    <el-form-item label="活动名称"
                  v-show="isActivity"
                  :rules="[{ required: isActivity, message: '活动名称', trigger: 'change' }]"
                  prop="activityId">
      <v-select2 v-model="form.activityId"
                 placeholder="查询活动"
                 v-bind="activityParams"
                 :width="width"></v-select2>
    </el-form-item>
    <el-form-item label="调查名称"
                  :rules="[{ required: true, message: '请输入调查名称', trigger: 'blur' }]"
                  prop="title">
      <v-input placeholder="请输入调查名称"
               v-model="form.title"
               :width="250"></v-input>
    </el-form-item>
    <el-form-item label="关联标签"
                  v-show="!isActivity"
                  :rules="[{ required: !isActivity, message: '请选择关联标签', trigger: 'change' }]"
                  prop="orgLabelId">
      <checkbox-plus ref="orgLabel"
                     :value.sync="form.orgLabelId"
                     :options="relationLabelCkOption"
                     mode="radio"
                     @onChange="labelChange" />
    </el-form-item>
    <el-form-item label="调查对象"
                  v-show="!isActivity"
                  :rules="[{ required: !isActivity, message: '请选择调查对象', trigger: 'change' }]"
                  prop="joinUserType">
      <checkbox-plus ref="joinUserType"
                     :value.sync="form.joinUserType"
                     type='default'
                     :options="joinUserTypeCkOption"
                     mode="checkbox-plus" />
    </el-form-item>
    <el-form-item label="限制到房号"
                  v-show="!isActivity"
                  :rules="[{ required: !isActivity, message: '活动名称', trigger: 'change' }]"
                  prop="limitRoom">
      <v-checkbox v-model="form.limitRoom"
                  label="限制"
                  type="default"></v-checkbox>勾选后，同一房号下只能有一位用户参与调查
    </el-form-item>
    <el-form-item label="封面"
                  :rules="[{ required: true, message: '封面图片', trigger: 'change' }]"
                  prop="icoUrl">
      <v-uploader :action="uploadURL"
                  :imgUrls.sync="form.icoUrl"
                  :imageWH="[690,294]">
        <template #tip>
          <span>注：建议宽690px高294px，图片小于512KB</span>
        </template>
      </v-uploader>
    </el-form-item>
    <el-form-item label="调查简介"
                  :rules="[{ required: true, message: '请输入调查简介', trigger: 'blur' }]"
                  prop="introduce">
      <v-ueditor v-model="form.introduce"></v-ueditor>
    </el-form-item>
    <el-form-item label="调查协议">
      <v-ueditor v-model="form.voteRule"></v-ueditor>
    </el-form-item>
    <el-form-item prop="communityIds"
                  label="调查项目"
                  :rules="[{required: true, message: '请选择服务项目', trigger: 'change'}]">
      <chosen-list-panel textAs="name"
                         :list.sync="form.communityIds"
                         @select="showCommunityMS" />
    </el-form-item>
    <el-form-item label="调查时间"
                  :rules="dateRules"
                  prop="surveyDate">
      <v-datepicker :minDate="minDate"
                    :startTime.sync="form.surveyDate.beginTime"
                    :endTime.sync="form.surveyDate.endTime"
                    :minuteStep="10"
                    type="rangedatetimer"></v-datepicker>
    </el-form-item>
    <multi-select :isShow.sync="multiConfig.communityMSVisible"
                  :searchUrl="multiConfig.searchUrl"
                  :backFill.sync="form.communityIds"
                  :searchParams="multiConfig.communitySearchParams"
                  :isMultiSelect="true"
                  :headers="multiConfig.communityHeaders"
                  title="项目列表"
                  :responseParams="multiConfig.communityResponseParam">
      <template #searchSlot>
        <v-input label="项目名称"
                 v-model="multiConfig.communitySearchParams.communityName" />
        <v-select label="区域类型"
                  v-model="multiConfig.communitySearchParams.searchRegionType"
                  :options="multiConfig.regionTypeOps" />
        <v-select2 label="所属区域"
                   v-bind="multiConfig.allRegionParams"
                   :subjoin="{regionType : multiConfig.communitySearchParams.searchRegionType}"
                   v-model="multiConfig.communitySearchParams.regionId" />
        <v-input label="所在省"
                 v-model="multiConfig.communitySearchParams.province" />
        <v-input label="所在城市"
                 v-model="multiConfig.communitySearchParams.city" />
        <v-input label="所在区"
                 v-model="multiConfig.communitySearchParams.area" />
        <!-- <v-select2 label="运营项目组"
                   v-model="multiConfig.communitySearchParams.operateRegionId"
                   v-bind="multiConfig.operateRegionParams" /> -->
        <v-select label="项目阶段状态"
                  v-model="multiConfig.communitySearchParams.communityStage"
                  :options="multiConfig.communityStageOps" />
        <v-select label="项目类型"
                  v-model="multiConfig.communitySearchParams.communityType"
                  :options="multiConfig.communityTypeOps" />
        <v-select label="项目状态"
                  v-model="multiConfig.communitySearchParams.communityStatus"
                  :options="multiConfig.communityStatusOps" />
      </template>
    </multi-select>
  </form-panel>
</template>

<script>
import { saveBaseURL, closeURL, uploadURL, getCategoryListURL, getActivitySelectURL, getCommunityURL, getOperateRegionURL, getCommunityStageURL, getCommunityTypeURL } from './../api'
import { relationLabelCkOption, joinUserTypeCompany, joinUserTypeHouse, joinUserTypeCampus, communityStatusMap, setCommunityStatusOps, regionTypeOps, activityPublisherTypeOps } from './../map'
import { CheckboxPlus, MultiSelect, ChosenListPanel } from 'components/bussiness'
import { vCheckbox, vUeditor, vUploader } from 'components/control'
import { allRegionParams } from 'common/select2Params'

export default {
  name: 'survey_base',
  props: {
    surveyData: {}
  },
  components: {
    CheckboxPlus,
    MultiSelect,
    ChosenListPanel,
    vCheckbox,
    vUeditor,
    vUploader
  },
  data () {
    let validateDate = (rule, value, callback) => {
      if (!value.beginTime || !value.endTime) {
        return callback(new Error('请填写调查时间!!'))
      } else {
        return callback()
      }
    }
    return {
      width: 182,
      uploadURL: uploadURL,
      categoryOps: [],
      activityPublisherTypeOps: activityPublisherTypeOps,
      relationLabelCkOption: relationLabelCkOption,
      joinUserTypeCkOption: joinUserTypeHouse,
      submitConfig: {
        submitUrl: saveBaseURL,
        submitText: '保存',
        submitMethod: 'POST'
      },
      isActivity: false,
      activityParams: {
        searchUrl: getActivitySelectURL,
        response: {
          text: 'activityName'
        },
        request: {
          text: 'activityName'
        }
      },
      minDate: new Date(),
      form: {
        id: undefined,
        categoryId: undefined,
        categoryName: '',
        activityPublisherType: 1,
        activityId: '',
        title: '',
        orgLabelId: undefined,
        orgLabelName: '',
        joinUserType: '',
        limitRoom: false,
        icoUrl: '',
        voteRule: '',
        introduce: '',
        state: 0,
        communityIds: [],
        surveyDate: {
          beginTime: '',
          endTime: ''
        },
        questions: []
      },
      dateRules: [{ required: true, validator: validateDate, trigger: 'blur' }],
      multiConfig: {
        communityMSVisible: false,
        searchUrl: getCommunityURL,
        communityHeaders: [
          {
            prop: 'communityName',
            label: '项目名称'
          },
          {
            prop: 'communityStatusStr',
            label: '项目状态',
            formatter (row) {
              return communityStatusMap[row.communityStatus]
            }
          },
          {
            prop: 'communityStage',
            label: '项目阶段'
          },
          {
            prop: 'provinceName',
            label: '所在省'
          },
          {
            prop: 'cityName',
            label: '所在市'
          },
          {
            prop: 'countryName',
            label: '所在区'
          },
          {
            prop: 'communityPhone',
            label: '项目电话'
          }
        ],
        operateRegionParams: {
          searchUrl: getOperateRegionURL
        },
        allRegionParams,
        communitySearchParams: {
          ismodal: 1,
          communityStatus: '0'
        },
        communityResponseParam: {
          id: 'id',
          name: 'communityName'
        },
        regionTypeOps: regionTypeOps,
        communityStageOps: [
          {
            text: '全部',
            value: undefined
          }
        ],
        communityTypeOps: [
          {
            text: '全部',
            value: undefined
          }
        ],
        communityStatusOps: setCommunityStatusOps(1),
        orgLabelId: undefined
      }
    }
  },
  mounted () {
    const { id } = this.$route.query
    if (id !== undefined) {
      if (!this.$route.query.isCopy) {
        this.$setBreadcrumb('编辑')
        this.submitConfig.submitMethod = 'PUT'
        if (this.surveyData.state === 1) {
          this.submitConfig.submitUrl = closeURL
          this.submitConfig.submitText = '关闭'
          this.submitConfig.submitContentType = 'application/x-www-form-urlencoded;charset=UTF-8'
        } else if (this.surveyData.state === 2 || this.surveyData.state === 3) {
          this.submitConfig.submitUrl = ''
          this.submitConfig.submitText = ''
        }
      } else {
        this.surveyData.state = this.$route.query.isCopy === 1 ? 0 : this.surveyData.state
      }
    } else {
      this.$setBreadcrumb('新增')
    }
    this.getCategoryOps()
    this.getCommunityType()
    this.getCommunityStage()
  },
  created () {
    if (Object.keys(this.surveyData).length) {
      this.getBaseInfo(this.surveyData)
    }
  },
  methods: {
    getBaseInfo (data) {
      Object.keys(this.form).forEach(key => {
        this.form[key] = data[key]
      })
      if (this.form.orgLabelId) {
        this.labelChange(undefined, this.form.orgLabelId)
      }
      this.form.communityIds = data.communitys.map(({ communityId, communityName }) => ({ id: communityId, name: communityName }))
      this.form.limitRoom = !!data.limitRoom
      this.form.joinUserType = Array.from(data.joinUserType.toString())
      this.form.id = this.$route.query.isCopy ? undefined : data.id
      this.form.state = this.$route.query.isCopy === 1 ? 0 : data.state
      this.isActivity = this.form.categoryName === '活动调查'
      this.form.surveyDate = {
        beginTime: data.beginTime,
        endTime: data.endTime
      }
    },
    // 获取调查类型的ops
    async getCategoryOps () {
      let _this_ = this
      _this_.categoryOps = []
      let res = await this.$axios.get(getCategoryListURL)
      console.log(res)
      if (res.status === 100) {
        if (res.data && res.data.length > 0) {
          res.data.forEach(item => {
            _this_.categoryOps.push({
              text: item.value,
              value: item.id
            })
          })
          // 修改类型被关闭后无法显示名称
          let target = this.categoryOps.find(item => item.value === this.form.categoryId)
          if (!target && this.$route.query.id) {
            this.categoryId = this.form.categoryId
            this.form.categoryId = this.form.categoryName
          }
        }
      }
    },
    // 关联标签change
    labelChange (index, pureCheckedOptions) {
      this.form.joinUserType = []
      if (pureCheckedOptions === 1) {
        this.joinUserTypeCkOption = joinUserTypeCompany
      } else if (pureCheckedOptions === 2) {
        this.joinUserTypeCkOption = joinUserTypeHouse
      } else if (pureCheckedOptions === 3) {
        this.joinUserTypeCkOption = joinUserTypeCampus
      }
    },
    // 获取多选园区检索的下拉框
    async getCommunityType () {
      let { data } = await this.$axios.get(getCommunityTypeURL)
      this.multiConfig.communityTypeOps = [...this.multiConfig.communityTypeOps, ...data.map(({ id, value }) => ({ text: value, value: id }))]
    },
    async getCommunityStage () {
      let { data } = await this.$axios.get(getCommunityStageURL)
      this.multiConfig.communityStageOps = [...this.multiConfig.communityStageOps, ...data.map(({ id, value }) => ({ text: value, value: id }))]
    },
    showCommunityMS () {
      this.multiConfig.communityMSVisible = true
    },
    // 调查类型change
    categoryChange (value, option) {
      this.form.categoryName = option.text
      if (option.text === '活动调查') {
        this.isActivity = true
      } else {
        this.isActivity = false
      }
    },

    async submitBefore (data) {
      if (data.state === 1) {
        let isOk = await this.$confirm('是否要关闭调查问卷！')
        return isOk && { id: data.id }
      } else {
        if (data.id) {
          this.submitConfig.submitMethod = 'PUT'
        }
        if (data.orgLabelId === this.form.orgLabelName) {
          data.orgLabelId = this.orgLabelId
        }
        let orgLabelObj = this.$refs.orgLabel.getCheckedOptions()
        data.orgLabelName = orgLabelObj.label
        data.joinUserType = data.joinUserType.join && data.joinUserType.join('')
        data.communityIds = data.communityIds.map(item => item.id)
        data.limitRoom = data.limitRoom ? 1 : 0
        data.beginTime = data.surveyDate.beginTime
        data.endTime = data.surveyDate.endTime
        return true
      }
    },
    submitSuccess (data, msg) {
      if (this.surveyData.state === 1) {
        return true
      }
      this.form.id = data
      this.$emit('contact', {
        method: 'setActiveLabel',
        data: {
          label: '问题信息',
          propData: this.form
        }
      })
      this.$message.success('保存基本信息成功')
      return false
    }
  }
}
</script>

<style>
</style>
