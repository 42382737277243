<template>
  <div class="SurveyForm-container">
    <tabs-panel :tabs="tabs"
                :tabRouter="false"
                :before="getDetail"
                :activeLabel.sync="activeLabel"
                @change="switchTab"></tabs-panel>
  </div>
</template>

<script>
import { TabsPanel } from 'components/bussiness'
import baseInfo from './surverFormComponent/base'
import questions from './surverFormComponent/questions'
import relation from './surverFormComponent/relation'
import { queryURL } from './api'

export default {
  name: 'SurveyForm',
  components: {
    TabsPanel
  },
  data () {
    return {
      tabs: [],
      commonProps: {
        surveyData: {
        }
      },
      activeLabel: '基础信息',
      disabled: true
    }
  },
  created () {
    if (this.$route.query.id) {
      if (!this.$route.query.isCopy) {
        this.disabled = false
      }
    }
    this.tabs = [{
      label: '基础信息',
      component: baseInfo,
      props: this.commonProps
    }, {
      label: '问题信息',
      component: questions,
      props: this.commonProps,
      disabled: this.disabled
    }, {
      label: '设置显示逻辑',
      component: relation,
      props: this.commonProps,
      disabled: this.disabled
    }]
  },
  methods: {
    async getData () {
      const { id } = this.$route.query
      const res = await this.$axios.get(`${queryURL}?id=${id}`)
      return res
    },
    async getDetail (next) {
      if (this.$route.query.id) {
        const res = await this.getData()
        if (res.data.questions.length > 0) {
          res.data.questions.map(item => {
            item.noRequired = item.required === 0
            item.isSupplement = item.isSupplement === 1
          })
          // 编辑时要判断问题长度设置按钮的disabeld的状态，如果是复制则不进行初始状态的判断，直接置为true
          this.tabs[2].disabled = res.data.questions.length < 2 || this.$route.query.isCopy
        } else {
          this.tabs[2].disabled = true
        }
        this.commonProps.surveyData = res.data
        if (res.data.state !== 0 && !this.$route.query.isCopy) {
          // 复制的时候不能隐藏掉问题设置按钮
          this.tabs.pop()
        }
        next()
      } else {
        next()
      }
    },
    // 切换tab时候 同步数据
    switchTab (index, tab) {
      if (index === 2) {
        if (tab.props.surveyData.questions.length < 2) {
          return false
        }
      }
      this.commonProps.surveyData = Object.assign(this.commonProps.surveyData, tab.props.surveyData)
    },
    setActiveLabel (data) {
      this.activeLabel = data.label
      this.tabs[1].disabled = false
      if (data.propData) {
        this.commonProps.surveyData = data.propData
        this.tabs[2].disabled = this.commonProps.surveyData.questions.length < 2
      }
    },
    setRelation (data) {
      this.tabs[2].disabled = data
    }
  }
}
</script>
