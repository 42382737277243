var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    {
      attrs: { placement: "right", width: "400", trigger: "click" },
      on: { show: _vm.showBefore },
      model: {
        value: _vm.popVisible,
        callback: function ($$v) {
          _vm.popVisible = $$v
        },
        expression: "popVisible",
      },
    },
    [
      _c(
        "div",
        { staticClass: "js-setRule" },
        [
          _c(
            "div",
            { staticClass: "js-confirm" },
            [
              _c("span", [_vm._v("当前用户选择本题选项，则显示以下题目")]),
              _c("v-button", { on: { click: _vm.submit } }, [_vm._v("确认")]),
            ],
            1
          ),
          _vm.questionData.length
            ? _c("checkbox-plus", {
                attrs: {
                  value: _vm.checkboxValue,
                  type: "default",
                  options: _vm.questionData,
                  mode: "checkbox-plus",
                  align: "vertical",
                },
                on: {
                  "update:value": function ($event) {
                    _vm.checkboxValue = $event
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c("v-button", { attrs: { slot: "reference" }, slot: "reference" }, [
        _vm._v("设置"),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }