import { generateMapByOpts, mapHelper } from 'common/utils'

// 是否抽奖
const isLotteryOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '是',
    value: 1
  },
  {
    text: '否',
    value: 0
  }
]

const isLotteryMap = generateMapByOpts(isLotteryOps)

const statusOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '待发布',
    value: 0
  },
  {
    text: '已发布',
    value: 1
  },
  {
    text: '结束',
    value: 2
  },
  {
    text: '关闭',
    value: 3
  }
]

const statusMap = generateMapByOpts(statusOps)

// 关联标签
const relationLabelCkOption = [
  {
    checked: true,
    label: '住宅',
    value: 2
  },
  {
    checked: false,
    label: '企业',
    value: 1
  },
  {
    checked: false,
    label: '学校',
    value: 3
  }
]
// 调查对象Map
const joinUserTypeHouse = [
  {
    checked: false,
    label: '业主',
    value: '1'
  },
  {
    checked: false,
    label: '亲属',
    value: '2'
  },
  {
    checked: false,
    label: '租客',
    value: '3'
  },
  {
    checked: false,
    label: '伙伴',
    value: '4'
  },
  {
    checked: false,
    label: '嘉宾',
    value: '5'
  }
]
const joinUserTypeCompany = [
  {
    checked: false,
    label: '组织超管',
    value: '1'
  },
  {
    checked: false,
    label: '组织成员',
    value: '2'
  },
  {
    checked: false,
    label: '组织管理员',
    value: '3'
  }
]
const joinUserTypeCampus = [
  {
    checked: false,
    label: '老师',
    value: '1'
  },
  {
    checked: false,
    label: '学生',
    value: '2'
  }
]
// 园区状态
const communityStatus = [
  {
    text: '正常',
    value: '0'
  },
  {
    text: '关闭',
    value: '1'
  }
]
const {
  map: communityStatusMap,
  setOps: setCommunityStatusOps
} = mapHelper.setMap(communityStatus)

const regionTypeOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '公司',
    value: 0
  },
  // {
  //   text: '园区公司分公司',
  //   value: 1
  // },
  {
    text: '管理项目组',
    value: 2
  }
]
// 活动组织类型
const activityPublisherTypeOps = [
  {
    text: '平台',
    value: 1
  }, {
    text: '租户',
    value: 2
  }, {
    text: '项目',
    value: 3
  }]

const {
  map: joinUserTypeHouseMap
} = mapHelper.setMap(joinUserTypeHouse.map(({ label, value }) => ({ text: label, value })))
const {
  map: joinUserTypeCampusMap
} = mapHelper.setMap(joinUserTypeCampus.map(({ label, value }) => ({ text: label, value })))
const {
  map: joinUserTypeCompanyMap
} = mapHelper.setMap(joinUserTypeCompany.map(({ label, value }) => ({ text: label, value })))

export {
  isLotteryOps,
  isLotteryMap,
  statusOps,
  statusMap,
  relationLabelCkOption,
  joinUserTypeHouse,
  joinUserTypeCampus,
  joinUserTypeCompany,
  communityStatusMap,
  setCommunityStatusOps,
  regionTypeOps,
  activityPublisherTypeOps,
  joinUserTypeHouseMap,
  joinUserTypeCampusMap,
  joinUserTypeCompanyMap
}
