var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form-panel",
    _vm._b(
      {
        ref: "formPanel",
        attrs: {
          form: _vm.form,
          submitBefore: _vm.submitBefore,
          submitSuccess: _vm.submitSuccess,
        },
      },
      "form-panel",
      _vm.submitConfig,
      false
    ),
    [
      _c(
        "el-form-item",
        {
          attrs: {
            label: "调查类型",
            rules: [
              { required: true, message: "请输入调查类型", trigger: "change" },
            ],
            prop: "categoryId",
          },
        },
        [
          _c("v-select", {
            attrs: { options: _vm.categoryOps, width: _vm.width },
            on: { change: _vm.categoryChange },
            model: {
              value: _vm.form.categoryId,
              callback: function ($$v) {
                _vm.$set(_vm.form, "categoryId", $$v)
              },
              expression: "form.categoryId",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isActivity,
              expression: "isActivity",
            },
          ],
          attrs: {
            label: "活动组织类型",
            rules: [
              {
                required: _vm.isActivity,
                message: "活动组织类型",
                trigger: "change",
              },
            ],
            prop: "activityPublisherType",
          },
        },
        [
          _c("v-select", {
            attrs: { options: _vm.activityPublisherTypeOps, width: _vm.width },
            model: {
              value: _vm.form.activityPublisherType,
              callback: function ($$v) {
                _vm.$set(_vm.form, "activityPublisherType", $$v)
              },
              expression: "form.activityPublisherType",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isActivity,
              expression: "isActivity",
            },
          ],
          attrs: {
            label: "活动名称",
            rules: [
              {
                required: _vm.isActivity,
                message: "活动名称",
                trigger: "change",
              },
            ],
            prop: "activityId",
          },
        },
        [
          _c(
            "v-select2",
            _vm._b(
              {
                attrs: { placeholder: "查询活动", width: _vm.width },
                model: {
                  value: _vm.form.activityId,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "activityId", $$v)
                  },
                  expression: "form.activityId",
                },
              },
              "v-select2",
              _vm.activityParams,
              false
            )
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          attrs: {
            label: "调查名称",
            rules: [
              { required: true, message: "请输入调查名称", trigger: "blur" },
            ],
            prop: "title",
          },
        },
        [
          _c("v-input", {
            attrs: { placeholder: "请输入调查名称", width: 250 },
            model: {
              value: _vm.form.title,
              callback: function ($$v) {
                _vm.$set(_vm.form, "title", $$v)
              },
              expression: "form.title",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isActivity,
              expression: "!isActivity",
            },
          ],
          attrs: {
            label: "关联标签",
            rules: [
              {
                required: !_vm.isActivity,
                message: "请选择关联标签",
                trigger: "change",
              },
            ],
            prop: "orgLabelId",
          },
        },
        [
          _c("checkbox-plus", {
            ref: "orgLabel",
            attrs: {
              value: _vm.form.orgLabelId,
              options: _vm.relationLabelCkOption,
              mode: "radio",
            },
            on: {
              "update:value": function ($event) {
                return _vm.$set(_vm.form, "orgLabelId", $event)
              },
              onChange: _vm.labelChange,
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isActivity,
              expression: "!isActivity",
            },
          ],
          attrs: {
            label: "调查对象",
            rules: [
              {
                required: !_vm.isActivity,
                message: "请选择调查对象",
                trigger: "change",
              },
            ],
            prop: "joinUserType",
          },
        },
        [
          _c("checkbox-plus", {
            ref: "joinUserType",
            attrs: {
              value: _vm.form.joinUserType,
              type: "default",
              options: _vm.joinUserTypeCkOption,
              mode: "checkbox-plus",
            },
            on: {
              "update:value": function ($event) {
                return _vm.$set(_vm.form, "joinUserType", $event)
              },
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isActivity,
              expression: "!isActivity",
            },
          ],
          attrs: {
            label: "限制到房号",
            rules: [
              {
                required: !_vm.isActivity,
                message: "活动名称",
                trigger: "change",
              },
            ],
            prop: "limitRoom",
          },
        },
        [
          _c("v-checkbox", {
            attrs: { label: "限制", type: "default" },
            model: {
              value: _vm.form.limitRoom,
              callback: function ($$v) {
                _vm.$set(_vm.form, "limitRoom", $$v)
              },
              expression: "form.limitRoom",
            },
          }),
          _vm._v("勾选后，同一房号下只能有一位用户参与调查\n  "),
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          attrs: {
            label: "封面",
            rules: [{ required: true, message: "封面图片", trigger: "change" }],
            prop: "icoUrl",
          },
        },
        [
          _c("v-uploader", {
            attrs: {
              action: _vm.uploadURL,
              imgUrls: _vm.form.icoUrl,
              imageWH: [690, 294],
            },
            on: {
              "update:imgUrls": function ($event) {
                return _vm.$set(_vm.form, "icoUrl", $event)
              },
              "update:img-urls": function ($event) {
                return _vm.$set(_vm.form, "icoUrl", $event)
              },
            },
            scopedSlots: _vm._u([
              {
                key: "tip",
                fn: function () {
                  return [
                    _c("span", [
                      _vm._v("注：建议宽690px高294px，图片小于512KB"),
                    ]),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          attrs: {
            label: "调查简介",
            rules: [
              { required: true, message: "请输入调查简介", trigger: "blur" },
            ],
            prop: "introduce",
          },
        },
        [
          _c("v-ueditor", {
            model: {
              value: _vm.form.introduce,
              callback: function ($$v) {
                _vm.$set(_vm.form, "introduce", $$v)
              },
              expression: "form.introduce",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "调查协议" } },
        [
          _c("v-ueditor", {
            model: {
              value: _vm.form.voteRule,
              callback: function ($$v) {
                _vm.$set(_vm.form, "voteRule", $$v)
              },
              expression: "form.voteRule",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          attrs: {
            prop: "communityIds",
            label: "调查项目",
            rules: [
              { required: true, message: "请选择服务项目", trigger: "change" },
            ],
          },
        },
        [
          _c("chosen-list-panel", {
            attrs: { textAs: "name", list: _vm.form.communityIds },
            on: {
              "update:list": function ($event) {
                return _vm.$set(_vm.form, "communityIds", $event)
              },
              select: _vm.showCommunityMS,
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          attrs: {
            label: "调查时间",
            rules: _vm.dateRules,
            prop: "surveyDate",
          },
        },
        [
          _c("v-datepicker", {
            attrs: {
              minDate: _vm.minDate,
              startTime: _vm.form.surveyDate.beginTime,
              endTime: _vm.form.surveyDate.endTime,
              minuteStep: 10,
              type: "rangedatetimer",
            },
            on: {
              "update:startTime": function ($event) {
                return _vm.$set(_vm.form.surveyDate, "beginTime", $event)
              },
              "update:start-time": function ($event) {
                return _vm.$set(_vm.form.surveyDate, "beginTime", $event)
              },
              "update:endTime": function ($event) {
                return _vm.$set(_vm.form.surveyDate, "endTime", $event)
              },
              "update:end-time": function ($event) {
                return _vm.$set(_vm.form.surveyDate, "endTime", $event)
              },
            },
          }),
        ],
        1
      ),
      _c("multi-select", {
        attrs: {
          isShow: _vm.multiConfig.communityMSVisible,
          searchUrl: _vm.multiConfig.searchUrl,
          backFill: _vm.form.communityIds,
          searchParams: _vm.multiConfig.communitySearchParams,
          isMultiSelect: true,
          headers: _vm.multiConfig.communityHeaders,
          title: "项目列表",
          responseParams: _vm.multiConfig.communityResponseParam,
        },
        on: {
          "update:isShow": function ($event) {
            return _vm.$set(_vm.multiConfig, "communityMSVisible", $event)
          },
          "update:is-show": function ($event) {
            return _vm.$set(_vm.multiConfig, "communityMSVisible", $event)
          },
          "update:backFill": function ($event) {
            return _vm.$set(_vm.form, "communityIds", $event)
          },
          "update:back-fill": function ($event) {
            return _vm.$set(_vm.form, "communityIds", $event)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-input", {
                  attrs: { label: "项目名称" },
                  model: {
                    value: _vm.multiConfig.communitySearchParams.communityName,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.multiConfig.communitySearchParams,
                        "communityName",
                        $$v
                      )
                    },
                    expression:
                      "multiConfig.communitySearchParams.communityName",
                  },
                }),
                _c("v-select", {
                  attrs: {
                    label: "区域类型",
                    options: _vm.multiConfig.regionTypeOps,
                  },
                  model: {
                    value:
                      _vm.multiConfig.communitySearchParams.searchRegionType,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.multiConfig.communitySearchParams,
                        "searchRegionType",
                        $$v
                      )
                    },
                    expression:
                      "multiConfig.communitySearchParams.searchRegionType",
                  },
                }),
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: {
                        label: "所属区域",
                        subjoin: {
                          regionType:
                            _vm.multiConfig.communitySearchParams
                              .searchRegionType,
                        },
                      },
                      model: {
                        value: _vm.multiConfig.communitySearchParams.regionId,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.multiConfig.communitySearchParams,
                            "regionId",
                            $$v
                          )
                        },
                        expression:
                          "multiConfig.communitySearchParams.regionId",
                      },
                    },
                    "v-select2",
                    _vm.multiConfig.allRegionParams,
                    false
                  )
                ),
                _c("v-input", {
                  attrs: { label: "所在省" },
                  model: {
                    value: _vm.multiConfig.communitySearchParams.province,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.multiConfig.communitySearchParams,
                        "province",
                        $$v
                      )
                    },
                    expression: "multiConfig.communitySearchParams.province",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "所在城市" },
                  model: {
                    value: _vm.multiConfig.communitySearchParams.city,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.multiConfig.communitySearchParams,
                        "city",
                        $$v
                      )
                    },
                    expression: "multiConfig.communitySearchParams.city",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "所在区" },
                  model: {
                    value: _vm.multiConfig.communitySearchParams.area,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.multiConfig.communitySearchParams,
                        "area",
                        $$v
                      )
                    },
                    expression: "multiConfig.communitySearchParams.area",
                  },
                }),
                _c("v-select", {
                  attrs: {
                    label: "项目阶段状态",
                    options: _vm.multiConfig.communityStageOps,
                  },
                  model: {
                    value: _vm.multiConfig.communitySearchParams.communityStage,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.multiConfig.communitySearchParams,
                        "communityStage",
                        $$v
                      )
                    },
                    expression:
                      "multiConfig.communitySearchParams.communityStage",
                  },
                }),
                _c("v-select", {
                  attrs: {
                    label: "项目类型",
                    options: _vm.multiConfig.communityTypeOps,
                  },
                  model: {
                    value: _vm.multiConfig.communitySearchParams.communityType,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.multiConfig.communitySearchParams,
                        "communityType",
                        $$v
                      )
                    },
                    expression:
                      "multiConfig.communitySearchParams.communityType",
                  },
                }),
                _c("v-select", {
                  attrs: {
                    label: "项目状态",
                    options: _vm.multiConfig.communityStatusOps,
                  },
                  model: {
                    value:
                      _vm.multiConfig.communitySearchParams.communityStatus,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.multiConfig.communitySearchParams,
                        "communityStatus",
                        $$v
                      )
                    },
                    expression:
                      "multiConfig.communitySearchParams.communityStatus",
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }