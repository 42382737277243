<template>
  <form-panel ref="form">
    <div class="relation-question"
         v-for="(question,index) in surveyData.questions"
         :key="index">
      <div class="queDes">投票问题{{index+1}}: {{ question.questionDes }}</div>
      <table class='option-container'
             v-if="question.optionType !== 3">
        <thead class='option-head'>
          <tr>
            <th style="width:80px;">类型</th>
            <th style="width:300px;">内容</th>
            <th class=''>显示逻辑</th>
          </tr>
        </thead>
        <tbody v-for="(option,idx) in question.options"
               :key="'option_'+idx"
               class="relation-tbody">
          <tr>
            <td> 问题选项{{ idx+1 }}</td>
            <td> {{ option.optionDes }}</td>
            <td>
              <relation-popover :data="relationQue"
                                :index="index"
                                :option="option"
                                @updateOption="setRule"></relation-popover>
              <template v-if='option.associatedSorts'>
                <div v-if='option.associatedSorts.length > 0'
                     style='color: #1b8cf2;display:inline-block;margin-left:10px;'>
                  选择后显示
                  <label v-for="(associated,assIdx) in option.associatedSorts"
                         :key="'associatedSorts_'+assIdx">
                    <span>问题{{associated+1}}{{option.associatedSorts.length === assIdx+1 ?'':'、'}}</span>
                  </label>
                </div>
              </template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <template #footerSlot>
      <v-button type="success"
                @click="publishSurvey"
                v-show="surveyData.questions.length > 0 && surveyData.state === 0">发布</v-button>
      <v-button plain
                @click="saveQuestion"
                v-show="surveyData.questions.length > 0 && surveyData.state === 0">保存并预览</v-button>
    </template>
    <mobile-preview :isShow.sync="isPreviewShow"
                    v-if="isPreviewShow"
                    :iframeUrl='iframeUrl'></mobile-preview>
  </form-panel>
</template>

<script>
import { saveQueURL, votePreviewURL, publishURL } from './../api'
import { MobilePreview } from 'components/bussiness'
import relationPopover from './relationPop'
export default {
  name: 'survey-relation',
  props: {
    surveyData: {}
  },
  components: {
    relationPopover,
    MobilePreview
  },
  data () {
    return {
      validataMsg: '',
      validata: true,
      popVisible: false,
      questionData: [],
      isPreviewShow: false,
      iframeUrl: 'http://m.baidu.com'
    }
  },
  computed: {
    relationQue () {
      let relationQuestions = []
      if (this.surveyData.questions.length > 0) {
        this.surveyData.questions.forEach((item, index) => {
          relationQuestions.push({
            label: '问题' + (index + 1) + '、' + item.questionDes,
            value: index
          })
        })
      }
      return relationQuestions
    }
  },
  created () {
    this.iframeUrl = votePreviewURL + this.surveyData.id
  },
  methods: {
    setRule (option, data) {
      this.$set(option, 'associatedSorts', data)
    },
    validateQuestion () {
      let questions = this.surveyData.questions
      let _this_ = this
      let requiredNum = 0
      if (questions.length > 0) {
        _this_.questionData = []
        questions.forEach(que => {
          que.required = que.noRequired ? 0 : 1
          if (que.required === 1) {
            requiredNum++
          }
          if (que.questionDes) {
            if (que.optionType !== 3) {
              if (que.options.length > 0) {
                que.options.forEach(option => {
                  if (!option.optionDes) {
                    _this_.validata = false
                    _this_.validataMsg = '问题选项不能为空'
                  }
                })
              }
            } else {
              que.options = []
            }
          } else {
            _this_.validata = false
            _this_.validataMsg = '问卷题目不能为空'
          }
          _this_.questionData.push(que)
        })
        if (requiredNum === 0) {
          _this_.validata = false
          _this_.validataMsg = '问卷至少要有一道必填题目'
        }
      }
    },
    async saveQuestion () {
      this.validateQuestion()
      if (this.validata) {
        let postData = {
          voteId: this.surveyData.id,
          questionData: this.questionData
        }
        let _this_ = this
        _this_.$axios({
          url: saveQueURL,
          method: 'post',
          data: postData
        }).then(res => {
          if (res.status === 100) {
            // 预览
            _this_.isPreviewShow = true
          }
        })
      } else {
        let isOk = await this.$alert(this.validataMsg)
        if (isOk) {
          this.$emit('contact', {
            method: 'setActiveLabel',
            data: {
              label: '问题信息'
            }
          })
        }
      }
    },
    async publishSurvey () {
      let isOk = await this.$confirm('温馨提示：发布后无法修改问题信息，可先点击预览按钮查看页面效果！')
      if (isOk) {
        let _this_ = this
        _this_.$axios.put(publishURL, _this_.$qs.stringify({ id: _this_.surveyData.id })).then(res => {
          if (res.status === 100) {
            _this_.$message.success('保存成功')
            _this_.$router.go(-1)
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.relation-question {
  .queDes {
    margin: 15px 10px;
    display: inline-block;
    font-size: 16px;
  }
  .option-container {
    width: 900px;
    margin-left: 10px;
    .option-head tr th {
      background-color: #e3f0ff;
      font-size: 14px;
      height: 45px;
      padding-left: 16px;
      color: #666666;
      font-weight: 400;
    }
    .relation-tbody {
      tr:nth-child(odd) {
        background-color: #f2f2f2;
        border-top: 2px solid #fff;
      }
      tr:nth-child(even) {
        background-color: #f8f8f8;
        border-top: 2px solid #fff;
      }
      tr td {
        height: 60px;
        padding-left: 16px;
      }
    }
  }
}
</style>
