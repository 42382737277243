// 获取列表
const getListURL = `${API_CONFIG.butlerBaseURL}questionnaire/list/managent`
// 导出列表
const exportListURL = `${API_CONFIG.butlerBaseURL}questionnaire/list/managent/export`
// 获取调查类型
const getCategoryListURL = `${API_CONFIG.butlerBaseURL}questionnaire/category/select`
// 获取全部参与项目
const getParticipationCommunityURL = `${API_CONFIG.butlerBaseURL}questionnaire/communitys/byvoteid`
// 设置抽奖链接
const setDrawURL = `${API_CONFIG.butlerBaseURL}questionnaire/drawurl`
// 新增基础信息
const saveBaseURL = `${API_CONFIG.butlerBaseURL}questionnaire/baseinfo`
// 编辑
const saveQueURL = `${API_CONFIG.butlerBaseURL}questionnaire/question`
// 预览Url
const votePreviewURL = `${API_CONFIG.h5BaseURL}h5/community/newVote/index.html#/preview?voteId=`
// 发布url
const publishURL = `${API_CONFIG.butlerBaseURL}questionnaire/publish`
// 关闭url
const closeURL = `${API_CONFIG.butlerBaseURL}questionnaire/close`
// 查询详情
const queryURL = `${API_CONFIG.butlerBaseURL}questionnaire/detail`
// 上传
const uploadURL = `${API_CONFIG.uploadURL}?module=shopgoods`
// 查询活动
const getActivitySelectURL = `${API_CONFIG.butlerBaseURL}questionnaire/activity/charge/select2`
// 获取项目列表
const getCommunityURL = `${API_CONFIG.controlBaseURL}community/getCommunityList`
// 获取运营项目组
const getOperateRegionURL = `${API_CONFIG.baseURL}serverCodewordAction!getOperateRegions.action`
// 获取项目阶段类型
const getCommunityStageURL = `${API_CONFIG.baseURL}serverCodewordAction!getProjectTypes.action`
// 获取项目类型
const getCommunityTypeURL = `${API_CONFIG.baseURL}serverCodewordAction!getRoomTypes.action`

export {
  getListURL,
  exportListURL,
  getCategoryListURL,
  getParticipationCommunityURL,
  setDrawURL,
  saveBaseURL,
  saveQueURL,
  votePreviewURL,
  publishURL,
  closeURL,
  queryURL,
  uploadURL,
  getActivitySelectURL,
  getCommunityURL,
  getOperateRegionURL,
  getCommunityStageURL,
  getCommunityTypeURL
}
