var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form-panel",
    {
      ref: "form",
      scopedSlots: _vm._u([
        {
          key: "footerSlot",
          fn: function () {
            return [
              _c(
                "v-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.surveyData.questions.length > 0 &&
                        _vm.surveyData.state === 0,
                      expression:
                        "surveyData.questions.length > 0 && surveyData.state === 0",
                    },
                  ],
                  attrs: { type: "success" },
                  on: { click: _vm.publishSurvey },
                },
                [_vm._v("发布")]
              ),
              _c(
                "v-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.surveyData.questions.length > 0 &&
                        _vm.surveyData.state === 0,
                      expression:
                        "surveyData.questions.length > 0 && surveyData.state === 0",
                    },
                  ],
                  attrs: { plain: "" },
                  on: { click: _vm.saveQuestion },
                },
                [_vm._v("保存并预览")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._l(_vm.surveyData.questions, function (question, index) {
        return _c("div", { key: index, staticClass: "relation-question" }, [
          _c("div", { staticClass: "queDes" }, [
            _vm._v(
              "投票问题" +
                _vm._s(index + 1) +
                ": " +
                _vm._s(question.questionDes)
            ),
          ]),
          question.optionType !== 3
            ? _c(
                "table",
                { staticClass: "option-container" },
                [
                  _c("thead", { staticClass: "option-head" }, [
                    _c("tr", [
                      _c("th", { staticStyle: { width: "80px" } }, [
                        _vm._v("类型"),
                      ]),
                      _c("th", { staticStyle: { width: "300px" } }, [
                        _vm._v("内容"),
                      ]),
                      _c("th", {}, [_vm._v("显示逻辑")]),
                    ]),
                  ]),
                  _vm._l(question.options, function (option, idx) {
                    return _c(
                      "tbody",
                      { key: "option_" + idx, staticClass: "relation-tbody" },
                      [
                        _c("tr", [
                          _c("td", [_vm._v(" 问题选项" + _vm._s(idx + 1))]),
                          _c("td", [_vm._v(" " + _vm._s(option.optionDes))]),
                          _c(
                            "td",
                            [
                              _c("relation-popover", {
                                attrs: {
                                  data: _vm.relationQue,
                                  index: index,
                                  option: option,
                                },
                                on: { updateOption: _vm.setRule },
                              }),
                              option.associatedSorts
                                ? [
                                    option.associatedSorts.length > 0
                                      ? _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              color: "#1b8cf2",
                                              display: "inline-block",
                                              "margin-left": "10px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                选择后显示\n                "
                                            ),
                                            _vm._l(
                                              option.associatedSorts,
                                              function (associated, assIdx) {
                                                return _c(
                                                  "label",
                                                  {
                                                    key:
                                                      "associatedSorts_" +
                                                      assIdx,
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        "问题" +
                                                          _vm._s(
                                                            associated + 1
                                                          ) +
                                                          _vm._s(
                                                            option
                                                              .associatedSorts
                                                              .length ===
                                                              assIdx + 1
                                                              ? ""
                                                              : "、"
                                                          )
                                                      ),
                                                    ]),
                                                  ]
                                                )
                                              }
                                            ),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]),
                      ]
                    )
                  }),
                ],
                2
              )
            : _vm._e(),
        ])
      }),
      _vm.isPreviewShow
        ? _c("mobile-preview", {
            attrs: { isShow: _vm.isPreviewShow, iframeUrl: _vm.iframeUrl },
            on: {
              "update:isShow": function ($event) {
                _vm.isPreviewShow = $event
              },
              "update:is-show": function ($event) {
                _vm.isPreviewShow = $event
              },
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }