<template>
  <el-popover placement="right" width="400" trigger="click" v-model="popVisible" @show="showBefore">
    <div class="js-setRule">
      <div class="js-confirm">
        <span>当前用户选择本题选项，则显示以下题目</span>
        <v-button @click="submit">确认</v-button>
      </div>
      <checkbox-plus v-if="questionData.length" :value.sync="checkboxValue" type="default" :options="questionData" mode="checkbox-plus" align="vertical" />
    </div>
    <v-button slot="reference">设置</v-button>
  </el-popover>
</template>

<script>
import Vue from 'vue'
import { Popover } from 'element-ui'
import { CheckboxPlus } from 'components/bussiness'
Vue.use(Popover)
export default {
  name: 'relation-pop',
  props: {
    data: {
      type: Array,
      default: () => {
        return []
      }
    },
    option: {},
    index: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      popVisible: false,
      checkboxValue: [],
      questionData: []
    }
  },
  components: {
    CheckboxPlus
  },
  methods: {
    submit () {
      console.log('plus的值', this.checkboxValue)
      this.$emit('updateOption', this.option, this.checkboxValue)
      this.popVisible = false
    },
    showBefore () {
      let _this_ = this
      _this_.questionData = []
      _this_.checkboxValue = []
      this.data.forEach(function (item, index) {
        if (index <= _this_.index) {
          item.disabled = true
        } else {
          item.disabled = false
        }
        _this_.questionData.push(item)
      })
    }
  }
}
</script>

<style lang="scss">
 .el-popover .js-setRule {
   .js-confirm {
     margin-bottom: 15px;
     display: flex;
     justify-content: space-between;
     align-items: center;
   }
 }
</style>
